<template>
  <section>
    <a-row :gutter="[0,4]">
      <a-col v-if="isCloseTop">
        <a-anchor :offsetTop="50" :wrapperStyle="{paddingLeft: 0,marginLeft: 0}">
          <div class="fix_border">
            <a-col :span="20">
              月份:
              <a-month-picker @change="handleReportChange" placeholder="请选择报告月份" v-model="ReportDateMoment"
                  :disabledDate="disabledDate" style=" margin-right: 4px;width:110px" :allowClear="false"
              />
              区域:
              <a-select show-search @change="handleAreaChange" v-model="params.PartName" placeholder="请选择大区"
                  style="width:130px;"
              >
                <a-select-option v-for="item in partList" :key="item.PartId" :value="item.PartName">
                  {{ item.PartName }}
                </a-select-option>
              </a-select>
              车型:
              <a-select v-model="params.carName" @change="carNameChange" style="width:130px;">
                <a-select-option v-for="(item,carIndex) in carTypes" :key="carIndex" :value="item">
                  {{ item }}
                </a-select-option>
              </a-select>
              <a-tooltip style="margin-left: 5px;" placement="top" title="是否去除新店或异常店?">
                异常店
                <a-icon type="info-circle"/>
                :
                <a-select @change="handleReportChange" v-model="params.copyType" style="width:50px;">
                  <a-select-option :value="0">
                    否
                  </a-select-option>
                  <a-select-option :value="1">
                    是
                  </a-select-option>
                </a-select>
              </a-tooltip>
            </a-col>
            <a-col style="float: right;">
          <span style="position: relative;float: right; top: -12px;margin-left:1vw" @click="isCloseTop=false">
                        <a-icon type="close"/>
                    </span>
              <a-tooltip placement="top" :title="`点击生成《${ moment(params.ReportDate).format('yyyy年MM月') }${params.carName + params.PartName}经营业绩分析报告》`">
                <a-button type="primary" @click="generateImages">生成长图</a-button>
              </a-tooltip>
            </a-col>
          </div>
        </a-anchor>
      </a-col>
      <div class="" ref="capture">
        <a-col>
          <a-card>
            <div class="report_bg">
              BDM业绩分析报告(水平事业 - {{ params.carName }}) <br>
              <span style="font-size: 22px;font-weight: 500"> {{
                  params.PartName  + moment(params.ReportDate).format("  yyyy年MM月  ") }}</span>
            </div>
            <a-descriptions bordered :column="3" style="padding-top: 30px">
              <a-descriptions-item label="销售店总数">{{ areaData["Total"] }}</a-descriptions-item>
              <a-descriptions-item label="数据使用店数">{{ areaData["DataSuccess"] }}</a-descriptions-item>
              <a-descriptions-item label="新店数量">{{ areaData["New"] }}</a-descriptions-item>
              <a-descriptions-item label="数据收集店数">{{ areaData["Used"] }}</a-descriptions-item>
              <a-descriptions-item label="数据未通过店数">{{ areaData["DataFail"] }}</a-descriptions-item>
              <a-descriptions-item label="异常店数量">{{ areaData["Exception"] }}</a-descriptions-item>
            </a-descriptions>
          </a-card>
        </a-col>
        <a-col>
          <a-card>
            <national-one :param-data.sync="paramData"/>
          </a-card>
        </a-col>
        <national-two :param-data="paramData" :car-select="params" :chart-value="chartData"/>
      </div>
    </a-row>
  </section>
</template>

<script>
import moment from "moment";
import { carTypes, filterChartCodes, filterCodes } from "@/views/performanceReport/NationalPerformanceReport/kpiCode";
import { disabledDate } from "@/until/dayFormat";
import { message } from "ant-design-vue";
import html2canvas from "html2canvas";
import nationalOne from "@/views/performanceReport/NationalPerformanceReport/NationalOne";
import nationalTwo from "@/views/performanceReport/NationalPerformanceReport/NationalTwo";

export default {
  name: "regionalPerformanceReport",
  components: { nationalOne, nationalTwo },
  data() {
    return {
      isCloseTop: true, moment,
      params: {
        ReportDate: moment().add(-1, "M").format("YYYY-MM-01"),
        PartName: "",
        carName: "所有车型",
        carKey: 0,
        copyType: 0, // 异常店
      },
      carTypes,
      partList: [],
      dealerList: [],
      areaData: {}, filterCodes, filterChartCodes,
      paramData: {},
      chartData: {},
    };
  },
  created() {
    this.getPart();
  },
  methods: {
    getParams() {
      if(Object.keys(this.$route.query).length > 0) {
        this.params = JSON.parse(this.$route.query.params.toString());
      }
      this.handleAreaChange();
    },
    carNameChange(val, index) {
      this.params.carKey = index.key;
    },
    async handleAreaChange() {
      if(this.params.PartName === "全国")
        await this.$router.push(`/nationalPerformanceReport?params=${JSON.stringify(this.params)}`);
      await this.GetDealerInputStatistics();
    },
    async handleReportChange() {
      await this.GetDealerInputStatistics();
    },
    async GetDealerInputStatistics() {
      let self = this;
      let res = await self.$Http.GetDealerInputStatistics(this.params);
      if(!res) {
        return;
      }
      self.areaData = res.Data;

      let rbs = await self.$Http.GetAreaDealerKpiReport({
        PartName: this.params.PartName,
        ReportDate: this.params.ReportDate,
        copyType: this.params.copyType,
        filterCodes: this._.uniq(filterCodes),
      });
      this.paramData = rbs.Data;

      let rCs = await this.$Http.GetAreaDealerKpiReportWithMonth({
        PartName: this.params.PartName,
        ReportDate: this.params.ReportDate,
        copyType: this.params.copyType,
        filterCodes: this._.uniq(filterChartCodes),
      });
      this.chartData = rCs.Data.NormalValue;
    },
    disabledDate,
    async getPart() {
      let self = this;
      let res = await self.$Http.GetPartListByPage({
        pageSize: 100,
        pageIndex: 1,
      });
      if(res.Data.length > 0) {
        self.partList = res.Data;
        self.partList.unshift({ PartId: "", PartName: "全国" });
        self.params.PartName = self.partList[1].PartName; // 默认安徽
      }
      this.getParams();
    },
    generateImages() {
      message.loading("生成中,请稍后....", 4);
      setTimeout(() => {
        html2canvas(this.$refs.capture, { dpi: 300 }).then(canvas => {
          const img = canvas.toDataURL();
          if(window.navigator["msSaveOrOpenBlob"]) {
            const atob1 = atob(img.split(",")[1]);
            let n = atob1.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
              u8arr[n] = atob1.charCodeAt(n);
            }
            const blob = new Blob([u8arr]);
            window.navigator["msSaveOrOpenBlob"](blob, `${moment(this.params.ReportDate).format("yyyy年MM月") + this.params.carName + this.params.PartName}经营业绩分析报告.png`);
          } else {
            const a = document.createElement("a");
            a.href = img;
            a.setAttribute("download", `${moment(this.params.ReportDate).format("yyyy年MM月") + this.params.carName + this.params.PartName}经营业绩分析报告.png`);
            a.click();
          }
        }).catch((error) => {
          this.$message.error("生成错误:" + error + ",请重新生成或者联系管理员!");
        });
      }, 1000);
    },
  },
  computed: {
    ReportDateMoment: {
      get() {
        return moment(this.params.ReportDate);
      },
      set(newValue) {
        this.params.ReportDate = newValue.format("YYYY-MM-01");
      },
    },
  },
};
</script>

<style lang="less" scoped>
@import "./performance.less";

</style>
